// @flow
import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import siteConfig from '../../config';

let {meta} = siteConfig;

type PropType = {
  title: string,
  url?: string,
  description?: string,
  image?: string
};
type StateType = {};


export class SiteSeo extends PureComponent<{}, {}> {
  state = {};
  render(){
    return (
      <Helmet>
        {/* General */}
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta name="image" content={meta.siteImage} />
        {/* Open Graph */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={meta.title} />
        <meta property="og:description" content={meta.description} />
        <meta property="og:url" content={meta.siteUrl} />
        <meta property="og:site_name" content={meta.siteName} />
        <meta property="og:image" content={meta.siteImage} />
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={meta.description} />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:image" content={meta.siteImage} />
        {/* Links */}
        {(meta.siteCSSLink.length > 0) && (<link href={meta.siteCSSLink} rel="stylesheet"/>)}
        {/* favicons */}
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#5bbad5" />
        <link rel="shortcut icon" href="/favicon/favicon.ico" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
    );
  }
}

class PageSeo extends PureComponent<PropType, StateType> {
  state = {};
  render(){
    let {description, image, url} = this.props;
    return (
      <Helmet>
        {/* General */}
        <title>{ this.props.title + ' - ' + meta.title} </title>
        {description && <meta name="description" content={description}/> }
        {image && <meta name="image" content={image} /> }
        {/* Open Graph */}
        <meta property="og:title" content={this.props.title} />
        <meta property="og:url" content={meta.siteUrl + (url ? url : '')} />
        {description && <meta property="og:description" content={description} /> }
        {image && <meta property="og:image" content={image} /> }
        {/* Twitter */}
        <meta name="twitter:title" content={this.props.title} />
        {description && <meta name="twitter:description" content={description} /> }
        {image && <meta name="twitter:image" content={image} /> }
      </Helmet>
    );
  }
}

export default PageSeo;
