let config = {
  meta: {
    description: "The_website_description.",
    title: "Amador Services",
    siteName: "Amador Services",
    siteUrl: "amadorsvc.com",
    siteImage: "/img/og-image.jpg",
    siteCSSLink: "",
  },
};

export default config;
