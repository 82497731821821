// @flow
import React, { PureComponent } from "react";
import Layout from '../components/layouts';

type PropType = {};
type StateType = {};

class NotFoundPage extends PureComponent<PropType, StateType> {
  state = {};
  render(){
    return (
      <Layout>
        <h1>Page not found</h1>
      </Layout>
    );
  }
}

export default NotFoundPage;
