// @flow
import React, { PureComponent } from 'react';
import {SiteSeo} from '../shared/Seo';

import '../../styles/app.less';

// Svg Symbols: <Symbols className="svg is-hidden" />
import Symbols from '-!svg-react-loader!../../assets/symbols/symbols.svg';

type PropType = {
  children: () => void
};
type StateType = {};

class Layout extends PureComponent<PropType, StateType> {
  state = {};
  render(){
    return (
      <div>
        <Symbols className="svg is-hidden"/>
        <SiteSeo />
        {this.props.children}
      </div>
    );
  }
}

export default Layout;
